<template>
  <div class="admin-edit-article">
    <loading-screen :is-loading="isSaving"></loading-screen>

    <div class="page-header">
      <h1 class="page-title">Edit Article</h1>
      <div class="actions">
        <a-button class="button-margin-left btn-rounded" icon="arrow-left" size="large" type="primary"
          @click.prevent="goBack">Back</a-button>
      </div>
    </div>

    <!-- Is Loading -->
    <div v-if="isLoadingCombined" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <!-- Basic Details -->
    <h3 v-if="!isLoadingCombined">Basic Details</h3>
    <div v-if="!isLoadingCombined" class="form">
      <div class="top-row">
        <div class="left" :class="{
          'has-custom-avatar': !shouldRemoveExistingButtonStrip,
        }">
          <a-form-item label="Button Strip">
            <div v-if="!shouldRemoveExistingButtonStrip && !fileList.length" class="custom-avatar">
              <div class="left">
                <article-button-strip size="large" :article="article" :tenant-id="tenantId"></article-button-strip>
              </div>
              <div class="right">
                <a-popconfirm title="Are you sure?" ok-text="Yes" cancel-text="No"
                  @confirm="shouldRemoveExistingButtonStrip = true">
                  <a href="#">Remove</a>
                </a-popconfirm>
              </div>
            </div>

            <a-upload :multiple="false" :file-list="fileList" :before-upload="beforeUpload" :remove="handleRemove">
              <a-button>
                <a-icon type="upload"></a-icon>
                Click to
                {{
                  !shouldRemoveExistingButtonStrip || fileList.length
                    ? "change"
                    : "upload"
                }}
              </a-button>
            </a-upload>
          </a-form-item>
        </div>
        <div class="right">
          <a-form-item label="Article Name">
            <a-input size="large" v-model="articleLocal.displayName"></a-input>
          </a-form-item>
        </div>
      </div>

      <a-form-item label="Context Quote">
        <a-input size="large" v-model="articleLocal.contextQuote"></a-input>
      </a-form-item>

      <a-form-item label="Visibility">
        <a-radio-group v-model="articleLocal.scope">
          <a-radio :value="1">Shown to organisation</a-radio>
          <!-- <a-radio :value="2">Shown to whole tenant</a-radio> -->
          <a-radio :value="3">Hidden from lists</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item label="Display Order">
        <a-radio-group v-model="articleLocal.rank">
          <a-radio v-for="(rank, i) in ranks" :key="i" :value="rank.rank">{{
            rank.label
            }}</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item label="This is Only a Minor Change">
        <a-radio-group v-model="articleLocal.isMinorChange">
          <a-radio :value="false">No</a-radio>
          <a-radio :value="true">Yes</a-radio>
        </a-radio-group>
      </a-form-item>
    </div>
    <!-- / Basic Details -->

    <!-- Article -->
    <h3 v-if="!isLoadingCombined">Article</h3>
    <div v-show="!isLoadingCombined" class="form last">
      <!-- <vue-editor v-if="shouldShowEditor" v-model="content" /> -->
      <quill-editor v-if="shouldShowEditor" v-model="content" />
    </div>
    <!-- / Article -->

    <a-button v-if="!isLoadingCombined" @click.prevent="save" class="btn-rounded save-button" size="large"
      type="primary">Save</a-button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LoadingScreen from "../../../../components/LoadingScreen.vue";
// const _ = require("lodash");
import articles from "../../../../api/guidance/articles";
import ArticleButtonStrip from "./ArticleButtonStrip.vue";
import guidanceHelpers from "../../../../helpers/guidance";
import axios from "axios";
// import { VueEditor } from "vue2-editor";
import { quillEditor } from 'vue-quill-editor';

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'


export default {
  name: "Edit",

  // components: { LoadingScreen, ArticleButtonStrip, VueEditor },
  components: { LoadingScreen, ArticleButtonStrip, quillEditor },

  data() {
    return {
      articleLocal: {},

      fileList: [],
      shouldRemoveExistingButtonStrip: false,

      content: "",
      content2: "",
      isLoadingContent: false,

      shouldShowEditor: false,

      isLoadingArticle: false,
      article: null,

      isSaving: false,
    };
  },

  created() {
    this.isLoading = true;
    this.loadArticle()
      .then(() => {
        this.articleLocal = {
          ...this.article["$v"],
          isMinorChange: false,
        };
        this.loadContent();
      })
      .catch((e) => {
        console.log(e);
        this.$message.info("Article not found");
        this.$router.push("/admin/guidance/articles");
      });

    // if (!this.article) {
    //   this.$message.info("Article not found");
    //   this.$router.push("/admin/guidance/articles");
    // } else {
    //   this.articleLocal = {
    //     ...this.article["$v"],
    //     isMinorChange: false,
    //   };
    //   this.loadContent();
    // }
  },

  methods: {
    ...mapActions("adminArticles", {
      updateArticleLocally: "updateArticleLocally",
    }),

    goBack() {
      this.$router.push("/admin/guidance/articles/" + this.article["$v"].id);
    },

    loadArticle() {
      return new Promise((resolve, reject) => {
        let vm = this;
        vm.isLoadingArticle = true;
        articles
          .getArticle(this.tenantId, this.articleId)
          .then((r) => {
            vm.isLoadingArticle = false;
            vm.article = r.data;
            resolve(r.data);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },

    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },

    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("Please upload a JPG or a PNG");
      }
      if (isJpgOrPng) {
        this.fileList = [file];
      }
      return false;
    },

    getValidationErrors() {
      let errors = [];
      if (this.articleLocal.displayName.trim().length == 0) {
        errors.push("Please provide a article name");
      }
      if (this.articleLocal.contextQuote.trim().length == 0) {
        errors.push("Please provide a context quote");
      }
      return errors;
    },

    async save() {
      let vm = this;

      // Validation
      let validationErrors = this.getValidationErrors();
      if (validationErrors.length) {
        this.$message.error(validationErrors[0]);
        return false;
      }

      vm.isSaving = true;

      // Let's update the article media streamed first
      let htmlString =
        `<html>
      <head>
      <meta name="viewport" content="width=device-width, initial-scale=1"/>
       <style>
            body { font-family: Arial, Helvetica, sans-serif; }
        </style>
      </head>
      <body>` +
        this.content +
        `</body></html>`;

      let blob = new Blob([htmlString], { type: "text/html" });

      try {
        await articles.attachArticleMediaStreamed(
          vm.tenantId,
          vm.article["$v"]["id"],
          blob
        );
      } catch (e) {
        vm.$message.error("Error attaching article");
        console.log(e);
      }

      // And then we can update the article details

      articles
        .updateArticle(vm.tenantId, vm.article["$v"].id, this.articleLocal)
        .then(async (r) => {
          if (this.fileList.length) {
            try {
              await articles.addArticleButtonStrip(
                vm.tenantId,
                this.article["$v"].id,
                vm.fileList[0]
              );
            } catch (e) {
              console.log(e);
              vm.$message.error("Error upading article button strip");
            }
          } else if (this.shouldRemoveExistingButtonStrip) {
            await articles.deleteArticleButtonStrip(
              this.tenantId,
              this.article["$v"]["id"]
            );
          }

          vm.updateArticleLocally(r.data);

          vm.$message.success("Article updated successfully");
          vm.$router.push("/admin/guidance/articles/" + vm.articleId);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    escapeHTML(unsafeText) {
      let div = document.createElement('div');
      div.innerText = unsafeText;
      return div.innerHTML;
    },

    async loadContent() {
      let vm = this;
      vm.isLoadingContent = true;

      try {
        let content = await axios
          .create()
          .get(this.source)
          .then((r) => r.data)
          .then((data) => {
            let inner = /<body.*?>([\s\S]*)<\/body>/.exec(data)[1];
            inner = inner.replace('\n<!--body start-->', '')
            inner = inner.replace('<!--body start-->', '')
            inner = inner.replace('<!--body end-->', '')
            inner = inner.trim();
            // return `<html><body>` + inner + `</body></html>`;
            // return `<html><body>` + inner + `</body></html>`;
            return inner;
          });
        this.shouldShowEditor = true;
        this.$nextTick(() => {
          // this.content = _.escape(content);
          // this.content = this.escapeHTML(content);
          this.content = content;
        });
      } catch (e) {
        console.log(e);
      }

      vm.isLoadingContent = false;
    },
  },

  computed: {
    ...mapGetters("missionControl", {
      presences: "presences",
    }),

    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
      isLoading: "isLoading",
    }),

    ...mapGetters("adminArticles", {
      articles: "articles",
    }),

    isLoadingCombined() {
      return this.isLoading || this.isLoadingContent || this.isLoadingArticle;
    },

    articleId() {
      return this.$route.params.id;
    },

    // article() {
    //   let vm = this;
    //   return _.find(this.articles, (t) => {
    //     return t["$v"].id == vm.articleId;
    //   });
    // },

    ranks() {
      return guidanceHelpers.listRankOptions();
    },

    source() {
      return this.article["$v"]["media"]["$v"]["source"]["$v"]["sourceUrl"];
    },
  },
};
</script>

<style lang="scss">
.admin-edit-article {
  padding-bottom: 40px;

  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .top-row {
    display: flex;

    .left {
      flex-shrink: 1;
    }

    .right {
      padding-left: 20px;
      flex-grow: 1;
    }
  }

  .has-custom-avatar {
    .ant-form-item-children {
      display: flex !important;
      flex-direction: horizontal;

      .custom-avatar {
        padding-right: 15px;
      }
    }
  }

  .form {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;

    margin-bottom: 20px;

    &.last {
      margin-bottom: 0;
    }
  }

  .custom-avatar {
    margin-bottom: 15px;
    display: flex;

    .left {
      flex-shrink: 1;
    }

    .right {
      padding-left: 20px;
      flex-grow: 1;
    }
  }

  .button-margin-left {
    margin-left: 10px;
  }

  .save-button {
    margin-top: 20px;
  }
}
</style>